<template>
  <div class="container-fluid row">
    <form>
              <div class="card-body mt-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mt-5">
                  <label for="username">รหัสนิสิต</label>
                  <input v-model="stu_id" type="text" min="1"
                    class="form-control form-control-sm" id="username" />
                </div>
                  </div>
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                  <label for="username">วันที่เริ่มต้น</label>
                  <input v-model="start" type="date" min="1"
                    class="form-control form-control-sm" id="username" />
                </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                  <label for="password">วันที่สิ้นสุด</label>
                  <input v-model="end" type="date"
                    class="form-control form-control-sm" id="password" />
                </div>
                  </div>
                </div>
               
                
                <div class="row mt-3">
                  <div class="col-md-3"></div>
                  <div class="col-md-6 text-center"> &nbsp;&nbsp;
                    <button @click="search()" type="button" class="btn btn-primary btn-sm">
                      ค้นหา
                    </button>&nbsp;&nbsp; 
                    <button @click="clear()" type="button" class="btn btn-danger btn-sm">
                      ล้างข้อมูลค้นหา
                    </button>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </form>
            <clip-loader :loading="loading" :color="color" :size="size" class="mt-3"></clip-loader>
    <div class="col-md-12" style="margin: 15px;margin-top: 30px" v-if="!loading">
      <div class="card" v-for="l in list" :key="l.id">
  <div class="card-header" style="background-color: #92d050">
    <span style="font-weight:bold"> รหัสนิสิต {{l.email}} {{l.firstname}} {{l.lastname}}</span>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <table class="table table-bordered">
        <thead>
          <tr style="text-align: center">
            <td scope="col">ครั้งที่</td>
            <td scope="col">จำนวนข้อสอบ</td>
            <td scope="col">คะแนน</td>
            <td scope="col" style="width:5%"></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r,ri) in l.result" :key="ri">
            <td scope="row"><span v-html="r.test_no"></span></td>
            <td scope="row">{{r.count}} ข้อ</td>
            <td scope="row">{{ r.score }}</td>
            <td>
                <button type="button" class="btn btn-warning btn-sm" @click="gotonewpage(l.student_id,r.test_no)">
                  <i class="fa fa-eye"></i>
                </button>
            </td>
          </tr>
        </tbody>
      </table></li>
  </ul>
</div>
      
    </div>

  </div>
</template>

<script>
import AnswerTrainingService from '../services/AnswerTrainingService';
import AnswerTimesService from '../services/AnswerTimesService';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: "Nav",
  components: {
    ClipLoader
  },
  props: {
    msg: String,
  },
  data() {
    return {
      list: [],
      score: 0,
      statustime: false,
      color: '#5bc0de',
      size: '50px',
      margin: '2px',
      radius: '2px',
      loading:true,
      start:'',
      end:'',
      stu_id:''
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    clear(){
      this.stu_id =''
      this.start = ''
      this.end = ''
      this.loading = true
      this.getdata()
    },
    search(){
      this.loading = true
console.log(this.start);
console.log(this.end);
this.getdata()

    },
    gotonewpage(student_id,test_no){
      var p = '/testresultall/'+student_id+'/' +test_no
      window.open(p, "_blank");

    },
    getdata() {
      this.list = [];
      AnswerTrainingService.getalltestoverall(this.start,this.end,this.stu_id).then((res) => {
        console.log(res.data);
        this.list = res.data
        this.loading = false
      })
      // AnswerTrainingService.getScoreStudent(this.currentUser.user_id).then((res) => {
      //   // console.log(res.data);
      //   this.score = res.data.score
      // })
      AnswerTimesService.getAnswerTime().then((res) => {
        // console.log(res.data[0].start_date);
        var dd = res.data[0].start_date
        var ee = res.data[0].end_date
        var now = new Date()
        var start_date = new Date(dd)
        var end_date = new Date(ee)
        start_date = new Date(start_date.getFullYear(),start_date.getMonth(),start_date.getDate(),now.getHours(),now.getMinutes(),0)
        end_date = new Date(end_date.getFullYear(),end_date.getMonth(),end_date.getDate(),now.getHours(),now.getMinutes(),0)
        now = new Date(now.getFullYear(),now.getMonth(),now.getDate(),end_date.getHours(),end_date.getMinutes(),0)
        // var end = dates.getFullYear()+'-'+dates.getMonth()+'-'+dates.getDate() + ' ' +res.data[0].time_end
        // console.log(start_date);
        // console.log(end_date);
        // console.log(now);
        if (now <= end_date) {
          // console.log(1);
          this.statustime = true
        } else if(now > start_date && now < end_date){
          // console.log(2);
          this.statustime = true
        }else {
          this.statustime = false
        }
        // console.log(this.statustime);
      })
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}
a{
  margin: 0px;
}

body {
  background-color: gray;
}</style>
