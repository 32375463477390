import http from "../http-common";

class AnswerStudentService {
  updateAnswerStudent(id, data) {
    return http.put(`/answer_training/${id}`, data);
  }  
  updatescore(id, data) {
    return http.put(`/answer_training/updatescore/${id}`, data);
  }
  getScoreStudent(id,test_no) {
    return http.get(`/answer_training/getscore/${id}/${test_no}`);
  }
  getalltestoverall(start,end,stu_id) {
    return http.get('/answer_training/getalltestoverall?start='+start+'&&end='+end+'&&student_id='+stu_id);
  }
  
  getallbtstuidandtestno(student_id,test_no,index_no) {
    return http.get(`/answer_training/getallbtstuidandtestno?student_id=`+student_id+'&&test_no='+test_no+'&&index_no='+index_no);
  }
  createAnswerStudent(data) {
    return http.post(`/answer_training`, data);
  }
  getAnswerStudents(student_id) {
    return http.get('/answer_training?student_id='+student_id);
  }
  getTestAnswerStudents(student_id,test_no) {
    return http.get('/answer_training/gettest?student_id='+student_id+'&&test_no='+test_no);
  } 
  getTestAnswerStudentsAll(student_id) {
    return http.get('/answer_training/getalltest?student_id='+student_id);
  } 
  deleteanswer(id,type) {
    return http.delete(`/answer_training/delete/`+id+'/'+type);
  }
  deleteallbystuidandno(id,no) {
    return http.delete(`/answer_training/deleteallbystuidandno/`+id+'/'+no);
  }
}

export default new AnswerStudentService();